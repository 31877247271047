<template>
 <b-card>
   <div>
     <b-row >
       <b-col
         cols="12"
         class="mb-2"
       >
         <div class="demo-inline-spacing" >
           <b-avatar variant="light-dark" size="4rem" style="margin:1rem; margin-left:2.3rem" v-if="edit_device.icon != undefined">
              <feather-icon :icon="possible_icons[edit_device.icon].icon  " size="30" />
            </b-avatar>
         <div>
             <h5 class="mb-0">
               {{ edit_device.name }}
             </h5>
             <small class="text-muted">
               #{{ edit_device.id }}
             </small>
           </div>
       </div>
        
       </b-col>
       <b-col md="5">
         <b-form-group
           label="Nome"
           label-for="name"
           style="margin-bottom: 3vh"
         >
           <b-form-input
             id="name"
             :value= edit_device.name
             v-model="edit_device.name"
           />
         </b-form-group>
       </b-col>
       <b-col md="5">
         <label for="tags-basic">Tag</label>
           <b-form-tags
             v-model="tags"
             input-id="tags-basic"
             class="mb-2"
             style="margin-bottom: 3vh"
           />
         </b-col>
       <b-col md="5">
         <b-form-group
           label="Descrição"
           label-for="description"
           style="margin-bottom: 3vh"
         >
           <b-form-input
             id="description"
             v-model="edit_device.description"
             :value= edit_device.description
           />
         </b-form-group>
       </b-col>       
       <b-col md="5">
         <b-form-group
           label="Estado"
           label-for="stats"
           style="margin-bottom: 3vh">
           <b-form-select
              id="group"
              v-model="selected_status"
              :options="possible_status"
            />
         </b-form-group>
        
       </b-col>
       
       <b-col md="5" v-if="edit_device.deviceType != '0'">
         <b-form-group
           label="Token"
           label-for="token"
           style="margin-bottom: 3vh"
         >
           <b-form-input
             id="token"
             :value= edit_device.idTokenDevice
             v-model="edit_device.idTokenDevice"
           />
         </b-form-group>
       </b-col>
       <b-col md="7" v-if="edit_device.deviceType != '0'"></b-col>
       <b-col md="5">
         <b-button
           v-ripple.400="'rgba(113, 102, 240, 0.15)'"
           variant="outline-success"
           @click="editar_dispositivo()"
           to="/devices"
         >
           <feather-icon
             icon="Edit2Icon"
             class="mr-50"
           />
           <span class="align-middle">Confirmar edição</span>
         </b-button>
       </b-col>
       <b-modal
       ok-variant="success"
       id="modal-edit_avatar"
       centered
       title="Editar avatar"
       ok-title="Editar"
       cancel-title="Cancelar"
     > 
     <!-- #TODO -->
       <div>
         <div  style="text-align:center; margin-bottom:2vh" >
         <b-avatar
           variant="light-dark"
           text= "falta API"
           size="10vh"
           button
         />
         </div>
         <div>
           <b-form-group
           label="Nome"
           label-for="name_avatar"
           style="margin-bottom: 3vh"
         >
           <b-form-input
             id="name_avatar"
           />
           </b-form-group>
         </div>
       </div>
     </b-modal>
     </b-row>
   </div>
 </b-card>
</template>
 
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BFormTags,} from 'bootstrap-vue'
import { BRow, BCol, BFormGroup, BFormInput, BAvatar, BFormSelect, BButton, BCard, BCardText, } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cookies from 'js-cookie'
import store from '@/store/index'
import axios from "axios";
import Ripple from 'vue-ripple-directive'
import api from '@/service/api'
 
export default {
 
 components: {
   FormWizard,
   TabContent,
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
   vSelect,
   BFormTags,
   BAvatar,
   BFormSelect,
   BButton,
   BCard,
   BCardText,
  
   // eslint-disable-next-line vue/no-unused-components
   ToastificationContent,
 },
 directives: {
    Ripple,
  },
 created(){
 
   if (Cookies.get("token_api") != null) {
     store.state.token_api = Cookies.get("token_api");
   }
 
   if(Cookies.get('token_api') == undefined){
     top.location.href = "/login";
   }

   if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }
 
    let selected_device = '&idDevice='+ store.state.selected_device;
    let selected_group = '?idGroup=' + store.state.active_group.id

   var get_device = {
     method: 'get',
     url: 'https://tekie.cloud:3031/devices' + selected_group + selected_device, 
     headers: {
       'Authorization': store.state.token_api
     }
   };

   var self = this;
   axios(get_device)
   .then(function (response) {
     self.edit_device = response.data.device.data[0];
     self.tags = String(self.edit_device.tag).split(',')
     self.selected_status = self.possible_status[self.edit_device.status].value
   })
   .catch(function (error) {
     //error);
     //"Deu erro");
   });      
 },
 data() {
  
   return {
     possible_icons: [
       {
         icon: 'BoxIcon',
         text: 'Cubo',
         id: 0,
         selected: 'flat-success'
       },
       {
         icon: 'BatteryIcon',
         text: 'Bateria',
         id: 1,
         selected: 'flat-darken-2'
       },
       {
         icon: 'DropletIcon',
         text: 'Humidade',
         id: 2,
         selected: 'flat-darken-2'
       },
       {
         icon: 'ThermometerIcon',
         text: 'Temperatura',
         id: 3,
         selected: 'flat-darken-2'
       },
       {
         icon: 'NavigationIcon',
         text: 'Seta',
         id: 4,
         selected: 'flat-darken-2'
       },
       {
         icon: 'Navigation2Icon',
         text: 'Seta#2',
         id: 5,
         selected: 'flat-darken-2'
       },
       {
         icon: 'MousePointerIcon',
         text: 'Mouse',
         id: 6,
         selected: 'flat-darken-2'
       },
       {
         icon: 'TruckIcon',
         text: 'Caminhão',
         id: 7,
         selected: 'flat-darken-2'
       },
       {
         icon: 'UserIcon',
         text: 'Usuário',
         id: 8,
         selected: 'flat-darken-2'
       },
       {
         icon: 'WatchIcon',
         text: 'Relógio',
         id: 9,
         selected: 'flat-darken-2'
       },
       {
         icon: 'EyeIcon',
         text: 'Olho',
         id: 10,
         selected: 'flat-darken-2'
       },
       {
         icon: 'MusicIcon',
         text: 'Música',
         id: 11,
         selected: 'flat-darken-2'
       },
       {
         icon: 'HeartIcon',
         text: 'Coração',
         id: 12,
         selected: 'flat-darken-2'
       },
       {
         icon: 'HomeIcon',
         text: 'Casa',
         id: 13,
         selected: 'flat-darken-2'
       },
    
     ],
     selected_status: '',
     device_name: '',
     device_description: '',
     device_status: '',
     status: 'Ativo',
     tags: [],
 
     active_device: store.state.selected_device,
     edit_device: [],     
 
     possible_status: [
       {
        text: 'Inativo',
        value: 'false',
      },
      {
        text: 'Ativo',
        value: 'true',
      },
     ],

   }
 },
 methods: {
   editar_dispositivo(){
     var self = this;     
     let data = JSON.stringify({
       id: self.edit_device.id,
       name: self.edit_device.name,
       description: self.edit_device_description,
       tag: String(self.tags),
       status: self.selected_status,
       groupId: 1,
       idTokenDevice: self.edit_device.idTokenDevice
     });
    
     var patch_device = {
     method: 'patch',
     url: 'https://tekie.cloud:3031/devices',    
     headers: {
       "Content-Type": "application/json",
       'Authorization': store.state.token_api
     },
     data: data,
   };
       //data)

     var self = this;
     axios(patch_device)
     .then(function (response) {
       let title = 'Notificação'
       let icon = 'BellIcon'
       let text = 'Dados do dispositivo alterados!'
       let variant = 'success'
       self.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
     })

     .catch(function (error) {
       //error);
       let title = 'Notificação'
       let icon = 'BellIcon'
       let text = 'Falha ao tentar atualizar os dados'
       let variant = 'danger'
       self.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
          },
        })
      });
    },
  },
}
 
</script>
 
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

